var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.shownLabel || _vm.shownDescription
        ? _c(
            "label",
            [
              _vm._v(" " + _vm._s(_vm.shownLabel) + " "),
              _vm.shownDescription
                ? _c("CIcon", {
                    directives: [
                      {
                        name: "c-tooltip",
                        rawName: "v-c-tooltip",
                        value: {
                          appendToBody: true,
                          content: _vm.shownDescription
                        },
                        expression:
                          "{ appendToBody: true, content: shownDescription }"
                      }
                    ],
                    attrs: { name: "cis-info-circle" }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.field
        ? _c(
            "CInputRadioGroup",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    isValid: _vm.checkIfValid,
                    checked: _vm.value,
                    id: _vm.safeId()
                  },
                  on: {
                    "update:checked": function($event) {
                      return _vm.onChange($event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "invalid-feedback",
                        fn: function() {
                          return [
                            _c("ValidatedInputErrors", {
                              attrs: {
                                label: _vm.shownLabel || _vm.shownPlaceholder,
                                field: _vm.field
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3664200507
                  )
                },
                "CInputRadioGroup",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }