var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "advanced-booking-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.AdvancedBookingPart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedRadioGroup", {
                            attrs: {
                              translationKey:
                                "playroom.bookingMaxDaysInAdvance",
                              field: _vm.$v.playroom.bookingMaxDaysInAdvance,
                              "data-cy": "bookingMaxDaysInAdvance-field",
                              value:
                                _vm.$v.playroom.bookingMaxDaysInAdvance.$model,
                              options: _vm.bookingMaxDaysInAdvanceOptions
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.bookingMaxDaysInAdvance,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedRadioGroup", {
                            attrs: {
                              translationKey:
                                "playroom.bookingMinHoursInAdvance",
                              field: _vm.$v.playroom.bookingMinHoursInAdvance,
                              "data-cy": "bookingMinHoursInAdvance-field",
                              value:
                                _vm.$v.playroom.bookingMinHoursInAdvance.$model,
                              options: _vm.bookingMinHoursInAdvanceOptions
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.bookingMinHoursInAdvance,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CCol", { attrs: { md: "4" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }