var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CRow",
        { staticClass: "product-variant-part" },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  ref: "validatedFormCard",
                  attrs: {
                    titleTranslationKey:
                      "components.playroomEditParts.ProductVariantPart.title",
                    ready: _vm.ready,
                    model: _vm.$v,
                    onSave: _vm.save
                  },
                  on: {
                    "part-save-status-type-change": function(status) {
                      return _vm.$emit("part-save-status-type-change", status)
                    }
                  }
                },
                [
                  _c(
                    "CForm",
                    [
                      _c(
                        "CRow",
                        _vm._l(_vm.playroom.possibleProductVariants, function(
                          possibleVariant
                        ) {
                          return _c(
                            "CCol",
                            { key: possibleVariant, attrs: { md: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "product-variant-field" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.$v.playroom.productVariant.$model,
                                        expression:
                                          "$v.playroom.productVariant.$model"
                                      }
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "product-variant-" + possibleVariant,
                                      "data-cy": "product-variant"
                                    },
                                    domProps: {
                                      value: possibleVariant,
                                      checked: _vm._q(
                                        _vm.$v.playroom.productVariant.$model,
                                        possibleVariant
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom.productVariant,
                                          "$model",
                                          possibleVariant
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for:
                                          "product-variant-" + possibleVariant
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(possibleVariant))]
                                  )
                                ]
                              ),
                              _c("hr"),
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "components.playroomEditParts.ProductVariantPart.variantText." +
                                        possibleVariant
                                    )
                                  )
                                }
                              })
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("HelpCard", {
                attrs: {
                  focusFor: _vm.focusFor,
                  name:
                    "components.playroomEditParts.ProductVariantPart.info.box1"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }