var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CRow",
        { staticClass: "temporal-availabilty-part" },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  ref: "validatedFormCard",
                  attrs: {
                    titleTranslationKey:
                      "components.playroomEditParts.TemporalAvailabiltyPart.generalAvability",
                    ready: _vm.ready,
                    model: _vm.$v,
                    onSave: _vm.save
                  },
                  on: {
                    "part-save-status-type-change": function(status) {
                      return _vm.$emit("part-save-status-type-change", status)
                    }
                  }
                },
                [
                  _c(
                    "CForm",
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12" } },
                            [
                              _c("ValidatedCheckbox", {
                                attrs: {
                                  translationKey: "playroom.alwaysAvailable",
                                  field: _vm.$v.playroom.alwaysAvailable,
                                  "data-cy": "alwaysAvailable-field",
                                  checked:
                                    _vm.$v.playroom.alwaysAvailable.$model
                                },
                                on: {
                                  "update:checked": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.alwaysAvailable,
                                      "$model",
                                      $event
                                    )
                                  },
                                  change: _vm.checkAlwaysAvailable
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.playroom.alwaysAvailable
                            ? _c(
                                "CCol",
                                { attrs: { md: "6" } },
                                [
                                  _c("ValidatedInput", {
                                    attrs: {
                                      translationKey: "playroom.availableFrom",
                                      field: _vm.$v.playroom.availableFrom,
                                      "data-cy": "available-from-field",
                                      type: "date",
                                      value:
                                        _vm.$v.playroom.availableFrom.$model
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom.availableFrom,
                                          "$model",
                                          $event
                                        )
                                      },
                                      focusChange: _vm.onFocusChange
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.playroom.alwaysAvailable
                            ? _c(
                                "CCol",
                                { attrs: { md: "6" } },
                                [
                                  _c("ValidatedInput", {
                                    attrs: {
                                      translationKey: "playroom.availableUntil",
                                      field: _vm.$v.playroom.availableUntil,
                                      "data-cy": "available-until-field",
                                      type: "date",
                                      value:
                                        _vm.$v.playroom.availableUntil.$model
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        return _vm.$set(
                                          _vm.$v.playroom.availableUntil,
                                          "$model",
                                          $event
                                        )
                                      },
                                      focusChange: _vm.onFocusChange
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("HelpCard", {
                attrs: {
                  focusFor: _vm.focusFor,
                  name:
                    "components.playroomEditParts.TemporalAvailabiltyPart.info.box1"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }