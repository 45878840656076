var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.playroomId
    ? _c(
        "div",
        { staticClass: "owner-playroom-edit-availability" },
        [
          _c("ProductVariantPart", {
            ref: "productVariantPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.productVariantPartSaveStatusType = status)
              }
            }
          }),
          _c("GeneralAvabilityPart", {
            ref: "temporalAvailabiltyPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.temporalAvailabiltyPartSaveStatusType = status)
              }
            }
          }),
          _c("AdvancedBookingPart", {
            ref: "advancedBookingPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.advancedBookingPartSaveStatusType = status)
              }
            }
          }),
          _c("BookingTimeRestrictionsPart", {
            ref: "bookingTimeRestrictionsPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.bookingTimeRestrictionsPartSaveStatusType = status)
              }
            }
          }),
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c(
                    "CButtonGroup",
                    { staticClass: "navButtons" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.onBack }
                        },
                        [_vm._v("<")]
                      ),
                      _c("CButton", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "global.saveAndNext",
                            expression: "'global.saveAndNext'"
                          }
                        ],
                        staticClass: "saveAndNext",
                        attrs: { color: "primary", disabled: !_vm.canSave },
                        on: { click: _vm.onNext }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }