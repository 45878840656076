var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "booking-time-restrictions-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.BookingTimeRestrictionsPart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "CCard",
                            [
                              _c("CCardHeader", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value:
                                      "components.playroomEditParts.BookingTimeRestrictionsPart.overnightTitle",
                                    expression:
                                      "'components.playroomEditParts.BookingTimeRestrictionsPart.overnightTitle'"
                                  }
                                ]
                              }),
                              _c(
                                "CCardBody",
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        { attrs: { md: "12" } },
                                        [
                                          _c("ValidatedCheckbox", {
                                            attrs: {
                                              field:
                                                _vm.$v.playroom
                                                  .allowOvernightBooking,
                                              checked:
                                                _vm.$v.playroom
                                                  .allowOvernightBooking.$model,
                                              translationKey:
                                                "playroom.allowOvernightBooking"
                                            },
                                            on: {
                                              "update:checked": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.$v.playroom
                                                    .allowOvernightBooking,
                                                  "$model",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.playroom.allowOvernightBooking
                                        ? [
                                            _c(
                                              "CCol",
                                              { attrs: { md: "6" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .overnightCheckInStartAt,
                                                    options:
                                                      _vm.overnightCheckInStartAtOptions,
                                                    value:
                                                      _vm.$v.playroom
                                                        .overnightCheckInStartAt
                                                        .$model,
                                                    translationKey:
                                                      "playroom.overnightCheckInStartAt"
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .overnightCheckInStartAt,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "6" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .overnightCheckInEndAt,
                                                    options: _vm.checkInToTimes,
                                                    value:
                                                      _vm.$v.playroom
                                                        .overnightCheckInEndAt
                                                        .$model,
                                                    translationKey:
                                                      "playroom.overnightCheckInEndAt"
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .overnightCheckInEndAt,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "6" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .overnightCheckOutStartAt,
                                                    options:
                                                      _vm.overnightCheckOutStartAtOptions,
                                                    value:
                                                      _vm.$v.playroom
                                                        .overnightCheckOutStartAt
                                                        .$model,
                                                    translationKey:
                                                      "playroom.overnightCheckOutStartAt"
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .overnightCheckOutStartAt,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "6" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .overnightCheckOutEndAt,
                                                    options:
                                                      _vm.checkOutToTimes,
                                                    value:
                                                      _vm.$v.playroom
                                                        .overnightCheckOutEndAt
                                                        .$model,
                                                    translationKey:
                                                      "playroom.overnightCheckOutEndAt"
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .overnightCheckOutEndAt,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "12" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .minimalBookingNights,
                                                    checked:
                                                      _vm.$v.playroom
                                                        .minimalBookingNights
                                                        .$model,
                                                    options:
                                                      _vm.minimalBookingNightsOptions,
                                                    value:
                                                      _vm.$v.playroom
                                                        .minimalBookingNights
                                                        .$model,
                                                    translationKey:
                                                      "playroom.minimalBookingNights",
                                                    append: _vm.$t(
                                                      "components.playroomEditParts.BookingTimeRestrictionsPart.nights"
                                                    )
                                                  },
                                                  on: {
                                                    "update:checked": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .minimalBookingNights,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .minimalBookingNights,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c("CCol", {
                                              directives: [
                                                {
                                                  name: "t",
                                                  rawName: "v-t",
                                                  value:
                                                    "components.playroomEditParts.BookingTimeRestrictionsPart.allowedDays",
                                                  expression:
                                                    "'components.playroomEditParts.BookingTimeRestrictionsPart.allowedDays'"
                                                }
                                              ],
                                              attrs: { md: "12" }
                                            }),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "12" } },
                                              [
                                                _c(
                                                  "CRow",
                                                  [
                                                    _c(
                                                      "CCol",
                                                      { attrs: { md: "4" } },
                                                      [_vm._v("Wochentag")]
                                                    ),
                                                    _c(
                                                      "CCol",
                                                      {
                                                        staticClass: "center",
                                                        attrs: { md: "3" }
                                                      },
                                                      [_vm._v("Verfügbar")]
                                                    ),
                                                    _c(
                                                      "CCol",
                                                      {
                                                        staticClass: "center",
                                                        attrs: { md: "2" }
                                                      },
                                                      [_vm._v("Check In")]
                                                    ),
                                                    _c(
                                                      "CCol",
                                                      {
                                                        staticClass: "center",
                                                        attrs: { md: "3" }
                                                      },
                                                      [_vm._v("Check Out")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  [
                                                    "onMonday",
                                                    "onTuesday",
                                                    "onWednesday",
                                                    "onThursday",
                                                    "onFriday",
                                                    "onSaturday",
                                                    "onSunday",
                                                    "beforeHoliday",
                                                    "onHoliday"
                                                  ],
                                                  function(day) {
                                                    return _c(
                                                      "CRow",
                                                      { key: day },
                                                      [
                                                        _c(
                                                          "CCol",
                                                          {
                                                            attrs: { md: "4" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "forms.playroom.allowedDays." +
                                                                    day +
                                                                    ".label"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "CCol",
                                                          {
                                                            staticClass:
                                                              "center",
                                                            attrs: { md: "3" }
                                                          },
                                                          [
                                                            _c(
                                                              "ValidatedCheckbox",
                                                              {
                                                                attrs: {
                                                                  field:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightBooking[
                                                                      day
                                                                    ],
                                                                  checked:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightBooking[
                                                                      day
                                                                    ].$model,
                                                                  value:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightBooking[
                                                                      day
                                                                    ].$model,
                                                                  translationKey:
                                                                    "playroom.allowedDays" +
                                                                    day
                                                                },
                                                                on: {
                                                                  "update:checked": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForOvernightBooking[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  },
                                                                  "update:value": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForOvernightBooking[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "CCol",
                                                          {
                                                            staticClass:
                                                              "center",
                                                            attrs: { md: "2" }
                                                          },
                                                          [
                                                            _c(
                                                              "ValidatedCheckbox",
                                                              {
                                                                attrs: {
                                                                  field:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightCheckIn[
                                                                      day
                                                                    ],
                                                                  checked:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightCheckIn[
                                                                      day
                                                                    ].$model,
                                                                  value:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightCheckIn[
                                                                      day
                                                                    ].$model,
                                                                  translationKey:
                                                                    "playroom.allowedDays" +
                                                                    day
                                                                },
                                                                on: {
                                                                  "update:checked": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForOvernightCheckIn[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  },
                                                                  "update:value": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForOvernightCheckIn[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "CCol",
                                                          {
                                                            staticClass:
                                                              "center",
                                                            attrs: { md: "3" }
                                                          },
                                                          [
                                                            _c(
                                                              "ValidatedCheckbox",
                                                              {
                                                                attrs: {
                                                                  field:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightCheckOut[
                                                                      day
                                                                    ],
                                                                  checked:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightCheckOut[
                                                                      day
                                                                    ].$model,
                                                                  value:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForOvernightCheckOut[
                                                                      day
                                                                    ].$model,
                                                                  translationKey:
                                                                    "playroom.allowedDays" +
                                                                    day
                                                                },
                                                                on: {
                                                                  "update:checked": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForOvernightCheckOut[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  },
                                                                  "update:value": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForOvernightCheckOut[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "CCard",
                            [
                              _c("CCardHeader", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value:
                                      "components.playroomEditParts.BookingTimeRestrictionsPart.hourlyTitle",
                                    expression:
                                      "'components.playroomEditParts.BookingTimeRestrictionsPart.hourlyTitle'"
                                  }
                                ]
                              }),
                              _c(
                                "CCardBody",
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        { attrs: { md: "12" } },
                                        [
                                          _c("ValidatedCheckbox", {
                                            attrs: {
                                              field:
                                                _vm.$v.playroom
                                                  .allowHourBooking,
                                              checked:
                                                _vm.$v.playroom.allowHourBooking
                                                  .$model,
                                              translationKey:
                                                "playroom.allowHourBooking"
                                            },
                                            on: {
                                              "update:checked": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.$v.playroom
                                                    .allowHourBooking,
                                                  "$model",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.playroom.allowHourBooking
                                        ? [
                                            _c(
                                              "CCol",
                                              { attrs: { md: "6" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .hourCheckInStartAt,
                                                    options:
                                                      _vm.hourCheckInStartAtOptions,
                                                    value:
                                                      _vm.$v.playroom
                                                        .hourCheckInStartAt
                                                        .$model,
                                                    translationKey:
                                                      "playroom.hourCheckInStartAt"
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .hourCheckInStartAt,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "6" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .hourCheckInEndAt,
                                                    options:
                                                      _vm.hourCheckInEndAtOptions,
                                                    value:
                                                      _vm.$v.playroom
                                                        .hourCheckInEndAt
                                                        .$model,
                                                    translationKey:
                                                      "playroom.hourCheckInEndAt"
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .hourCheckInEndAt,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "12" } },
                                              [
                                                _c("ValidatedSelect", {
                                                  attrs: {
                                                    field:
                                                      _vm.$v.playroom
                                                        .minimalBookingHours,
                                                    checked:
                                                      _vm.$v.playroom
                                                        .minimalBookingHours
                                                        .$model,
                                                    options:
                                                      _vm.minimalBookingHoursOptions,
                                                    value:
                                                      _vm.$v.playroom
                                                        .minimalBookingHours
                                                        .$model,
                                                    translationKey:
                                                      "playroom.minimalBookingHours",
                                                    append: _vm.$t(
                                                      "components.playroomEditParts.BookingTimeRestrictionsPart.hours"
                                                    )
                                                  },
                                                  on: {
                                                    "update:checked": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .minimalBookingHours,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.$v.playroom
                                                          .minimalBookingHours,
                                                        "$model",
                                                        $event
                                                      )
                                                    },
                                                    focusChange:
                                                      _vm.onFocusChange
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c("CCol", {
                                              directives: [
                                                {
                                                  name: "t",
                                                  rawName: "v-t",
                                                  value:
                                                    "components.playroomEditParts.BookingTimeRestrictionsPart.allowedDays",
                                                  expression:
                                                    "'components.playroomEditParts.BookingTimeRestrictionsPart.allowedDays'"
                                                }
                                              ],
                                              attrs: { md: "12" }
                                            }),
                                            _c(
                                              "CCol",
                                              { attrs: { md: "12" } },
                                              [
                                                _c(
                                                  "CRow",
                                                  [
                                                    _c(
                                                      "CCol",
                                                      { attrs: { md: "4" } },
                                                      [_vm._v("Wochentag")]
                                                    ),
                                                    _c(
                                                      "CCol",
                                                      {
                                                        staticClass: "center",
                                                        attrs: { md: "3" }
                                                      },
                                                      [_vm._v("Verfügbar")]
                                                    ),
                                                    _c(
                                                      "CCol",
                                                      {
                                                        staticClass: "center",
                                                        attrs: { md: "2" }
                                                      },
                                                      [_vm._v("Check In")]
                                                    ),
                                                    _c(
                                                      "CCol",
                                                      {
                                                        staticClass: "center",
                                                        attrs: { md: "3" }
                                                      },
                                                      [_vm._v("Check Out")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  [
                                                    "onMonday",
                                                    "onTuesday",
                                                    "onWednesday",
                                                    "onThursday",
                                                    "onFriday",
                                                    "onSaturday",
                                                    "onSunday",
                                                    "beforeHoliday",
                                                    "onHoliday"
                                                  ],
                                                  function(day) {
                                                    return _c(
                                                      "CRow",
                                                      { key: day },
                                                      [
                                                        _c(
                                                          "CCol",
                                                          {
                                                            attrs: { md: "4" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "forms.playroom.allowedDays." +
                                                                    day +
                                                                    ".label"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "CCol",
                                                          {
                                                            staticClass:
                                                              "center",
                                                            attrs: { md: "3" }
                                                          },
                                                          [
                                                            _c(
                                                              "ValidatedCheckbox",
                                                              {
                                                                attrs: {
                                                                  field:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyBooking[
                                                                      day
                                                                    ],
                                                                  checked:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyBooking[
                                                                      day
                                                                    ].$model,
                                                                  value:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyBooking[
                                                                      day
                                                                    ].$model,
                                                                  translationKey:
                                                                    "playroom.allowedDays" +
                                                                    day
                                                                },
                                                                on: {
                                                                  "update:checked": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForHourlyBooking[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  },
                                                                  "update:value": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForHourlyBooking[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "CCol",
                                                          {
                                                            staticClass:
                                                              "center",
                                                            attrs: { md: "2" }
                                                          },
                                                          [
                                                            _c(
                                                              "ValidatedCheckbox",
                                                              {
                                                                attrs: {
                                                                  field:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyCheckIn[
                                                                      day
                                                                    ],
                                                                  checked:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyCheckIn[
                                                                      day
                                                                    ].$model,
                                                                  value:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyCheckIn[
                                                                      day
                                                                    ].$model,
                                                                  translationKey:
                                                                    "playroom.allowedDays" +
                                                                    day
                                                                },
                                                                on: {
                                                                  "update:checked": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForHourlyCheckIn[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  },
                                                                  "update:value": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForHourlyCheckIn[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "CCol",
                                                          {
                                                            staticClass:
                                                              "center",
                                                            attrs: { md: "3" }
                                                          },
                                                          [
                                                            _c(
                                                              "ValidatedCheckbox",
                                                              {
                                                                attrs: {
                                                                  field:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyCheckOut[
                                                                      day
                                                                    ],
                                                                  checked:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyCheckOut[
                                                                      day
                                                                    ].$model,
                                                                  value:
                                                                    _vm.$v
                                                                      .playroom
                                                                      .allowedDaysForHourlyCheckOut[
                                                                      day
                                                                    ].$model,
                                                                  translationKey:
                                                                    "playroom.allowedDays" +
                                                                    day
                                                                },
                                                                on: {
                                                                  "update:checked": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForHourlyCheckOut[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  },
                                                                  "update:value": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.$v
                                                                        .playroom
                                                                        .allowedDaysForHourlyCheckOut[
                                                                        day
                                                                      ],
                                                                      "$model",
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "12" } },
                        [
                          _c("ValidatedSelect", {
                            attrs: {
                              field: _vm.$v.playroom.minutesForCleaning,
                              checked:
                                _vm.$v.playroom.minutesForCleaning.$model,
                              options: _vm.minutesForCleaningOptions,
                              value: _vm.$v.playroom.minutesForCleaning.$model,
                              translationKey: "playroom.minutesForCleaning",
                              append: _vm.$t(
                                "components.playroomEditParts.BookingTimeRestrictionsPart.hours"
                              )
                            },
                            on: {
                              "update:checked": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.minutesForCleaning,
                                  "$model",
                                  $event
                                )
                              },
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.minutesForCleaning,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "components.playroomEditParts.BookingTimeRestrictionsPart.info.box1"
            }
          }),
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "components.playroomEditParts.BookingTimeRestrictionsPart.info.box2"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }